interface Props {
    name: string,
    img: string,
    text: string
}

const Card = (props: Props) => {
    return (
        <article className="card">
            <img src={props.img} alt="" className="card-img"/>
            <h3 className="card-header">{props.name}</h3>
            <p className="card-text">{props.text}</p>
        </article>
    );
};

export default Card;