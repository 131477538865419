import React from 'react';

interface Props {
    currentDay: string,
    activities: Activity[],
    ursvik: boolean,
}
interface Activity {
    day: string,
    time: string,
    type: string,
    room: string,
    grade: string,
    key: number
}
const Day = (props: Props) => {

    let today = props.currentDay;
    today += "dag";
    
    return (
        <div className="day-wrapper">
            <h3 className="day-header">{today}</h3>
            <ul className="activity-list">
                {props.activities.map(activity => {
                    return (activity.day === props.currentDay ? 
                        <li className="activity-wrapper" key={activity.key}>
                            <ul className="time-wrapper">
                                <li><strong>{activity.time}</strong></li>
                                {props.ursvik ? <li>{activity.room}</li> : null}
                                <li>{activity.type}</li>
                                <li>{activity.grade}</li>
                            </ul>
                        </li>
                    : null)
                })}
            </ul>
        </div>
    );
};

export default Day;