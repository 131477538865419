import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from './Button';
import logo from '../../img/Forca-guld-transparent-small-cut.png';
import SolnaOverlay from './SolnaOverlay';
import UrsvikOverlay from './UrsvikOverlay';

const Startpage = () => {
    const [solnaVisibility, setSolnaVisibility] = useState<boolean>(false);
    const [ursvikVisibility, setUrsvikVisibility] = useState<boolean>(false);
    
    return (
        <section className="startpage-wrapper" id="start">
            <CSSTransition in={ursvikVisibility} timeout={400} classNames="club-transition" unmountOnExit>
                <UrsvikOverlay/>
            </CSSTransition>
            <CSSTransition in={solnaVisibility} timeout={400} classNames="club-transition" unmountOnExit>
                <SolnaOverlay/>
            </CSSTransition>
            <h1 className="sr-only">FORCA FIGHTING</h1> {/*This is for screen readers only */}
            <img src={logo} alt="En man som tar stryptag på ett lejon bakifrån omgivna av en olivkrans. Under bilden står texten Forca Fighting." className={`forca-logo ${solnaVisibility || ursvikVisibility ? 'transparent' : ''}`}/>
            <div className="button-wrapper">
                <Button clubName="solna" buttonClass="button-solna" visibility={ursvikVisibility} setVisibility={setSolnaVisibility}/>
                <Button clubName="ursvik" buttonClass="button-ursvik" visibility={solnaVisibility} setVisibility={setUrsvikVisibility}/>
            </div>
            {/* <Arrow/> */}
        </section>
    );
};

export default Startpage;