interface Props {
    menuExpand: boolean,
    setMenuExpand: (param: boolean) => void,
    showMenu: boolean,
    setShowMenu: (param: boolean) => void
}

const Hamburger = (props: Props) => {
    return (
        <button className={`hamburger`} aria-label="menu dropdown button" onClick={() => props.setShowMenu(!props.showMenu)}>
            <div className={`hamburger-line ${props.showMenu ? "close-top" : ""}`}></div>
            <div className={`hamburger-line ${props.showMenu ? "close-middle" : ""}`}></div>
            <div className={`hamburger-line ${props.showMenu ? "close-bottom" : ""}`}></div>
        </button>
    );
};

export default Hamburger;


// onClick={() => props.setMenuExpand(!props.menuExpand)}