import Card from "./Card";

interface ICard {
    name: string,
    img: string,
    text: string
}
interface Props {
    id: string,
    cards: ICard[],
    heading: string
}
const Cards = (props: Props) => {
    
    return (
        <section className={`cards-wrapper ${props.id === "trainers" ? "trainers" : null}`} id={props.id}>
            <h2 className="cards-heading">{props.heading}</h2>
            <div className="cards-div">
                {props.cards.map(card => {
                    return(
                        <Card name={card.name} img={card.img} text={card.text} key={card.name}/>
                    )
                })}
            </div>
        </section>
    );
};

export default Cards;