import Startpage from "./Startpage";
import About from "./About";
import Pictures from "./Pictures";
import Cards from "./Cards";
import Contact from "./Contact";
import wrestlers from '../../img/greek-wrestlers-wreath.svg';
import submission from '../../img/greek-submission-wreath.svg';
import fighters from '../../img/greek-punch-wreath.svg';
import kick from '../../img/greek-kick-wreath.svg';
import weightlifter from '../../img/weight-lifter-wreath.svg';
import wreath from '../../img/forca-krans.svg';
import Rules from "./Rules";


interface ClubContact {
    club: string,
    mail: string,
    tel: string,
    altTel: string,
    address: string
}
interface ICard {
    name: string,
    img: string,
    text: string
}
interface Props {
    contactInfo: ClubContact[]
}
const Main = (props: Props) => {

    const styles:ICard[] = [
        {name: "SUBMISSION WRESTLING", img: submission, text: "Submission wrestling, även kallad grappling, är en mix av sporterna judo, brottning och brasiliansk jiu jitsu. Denna träningsform passar dig som vill lära dig fristilsbrottning med låsningar, kast och svep."},
        {name: "MMA", img: fighters, text: "MMA står för Mixed Martial Arts. Kampsporten grundades för att kunna utse den bästa kampsportsstilen i världen genom att låta utövare av olika kamsportsstilar möta varandra. Idag är det sporten mer som namnet antyder, en blandning av många olika kampsportsstilar. Inom MMA:n är det tillåtet att använda de flesta tekniker för att vinna över sin motståndare."},
        {name: "THAIBOXNING", img: kick, text: "Thaiboxning har sitt ursprung i Thailand och har utövats i Sverige sedan sent 70-tal. Inom thaiboxningen är det tillåtet att använda sig av tekniker som slag, sparkar, knän och armbågar."},
        {name: "BROTTNING", img: wrestlers, text: "Fristilsbrottningen har anor som sträcker sig hundratals år bakåt i historien. Skillnaden mellan fristilsbrottning och den grek-romerska brottningen är att grepp över hela kroppen tillåtet i fristilsbrottning."},
        {name: "STYRKETRÄNING", img: weightlifter, text: "I Forca Fightings alla lokaler finns fria vikter som alla medlemmar har tillgång till under träningarna. Träningar i alla stilar innefattar också en stor mängd styrketräning."}
    ];
    const trainers:ICard[] = [
        {name: "Visar Kelmendi", img: wreath, text: "Den yngre brodern av de två grundarna av Forca fighting MMA. Med över 20 års erfarenhet av MMA har han varit delaktig i att ta fram många framgångsrika svenska mästare, landslags- och professionella utövare."},
        {name: "Sadri Kelmendi", img: wreath, text: "Den äldre brodern av de två grundarna av Forca fighting MMA. Även Sadri har över 20 års erfarenhet av MMA och har tillsammans med brorsan varit delaktig i att ta fram många framgångsrika svenska mästare, landslags- och professionella utövare."},
        {name: "Martin Sjunnestrand", img: wreath, text: "Tidigare svensk mästare i shootfighting. Martin har över 10 års erfarenhet som tränare på Forca fighting MMA."},
        {name: "Camilo Laserna Vargas", img: wreath, text: "Camilo har tidigare erfarenhet av ledarroller inom AIK:s ungdomsfotboll. Han är utbildad MMA-tränare och har varit delaktig i Forca fighting i över 3 år. Camillo är även far till vår framtida stjärna Gabriel Laserna."},
        {name: "Bartosz Wójcikiewicz", img: wreath, text: "Forca fightings stora stjärna som ni kommer se mycket mer av framöver på den internationella scenen. Bartosz håller i MMA-träningarna på Forca fighting Solna."},
        {name: "Gabriel Laserna", img: wreath, text: "Gabriel är svensk mästare i MMA och Forca fightings framtida stjärna. Gabriel är även han utbildad MMA-tränare. Gabriel håller thaiboxning för nybörjare och motionärer på Forca Fighting Solna."},
        
    ]
    return (
        <>
            <Startpage/>
            <About/>
            {/* <Pictures/> */}
            <Cards id="styles" cards={styles} heading="STILAR"/>
            <Cards id="trainers" cards={trainers} heading="TRÄNARE"/>
            <Rules/>
            <Contact contactInfo={props.contactInfo} club="both"/>
        </>
    );
};

export default Main;