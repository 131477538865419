import { Link as ScrollLink } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const DesktopNav = () => {
    const {pathname} = useLocation();
    const onStartPage: boolean = pathname === '/';
    
    return (
        <nav className="desktop-nav">
            <ul>
                <li>{
                    onStartPage ?
                        <ScrollLink to="start" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Våra klubbar</ScrollLink>
                    :
                        <Link to="/" className="nav-link">&lt; Startsida</Link>
                }</li>
                {onStartPage ?
                    <>
                        <li>
                            <ScrollLink to="about" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Om oss</ScrollLink>
                        </li>
                    {/* <li>
                        <ScrollLink to="pictures" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Bilder</ScrollLink>
                    </li> */}
                        <li>
                            <ScrollLink to="styles" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Stilar</ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="trainers" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Tränare</ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="rules" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Viktig info</ScrollLink>
                        </li>
                    </>
                :
                    <>
                        <li>
                            <ScrollLink to="schedule" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Schema</ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="rules" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Viktig info</ScrollLink>
                        </li>
                        <li>
                            <ScrollLink to="prices" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Priser</ScrollLink>
                        </li>
                    </>
                }
                <li>
                    <ScrollLink to="contact" spy={true} activeClass='current-scroll' smooth={true} className='nav-link'>Kontakt</ScrollLink>
                </li>
            </ul>
        </nav>
    );
};

export default DesktopNav;