import { useState } from 'react';
import Header from './components/Main/Header';
import MobileMenu from './components/Main/MobileMenu';
import {CSSTransition} from 'react-transition-group';
import { Routes, Route } from 'react-router-dom';
import Main from './components/Main/Main';
import Solna from './components/Main/Solna';
import Ursvik from './components/Main/Ursvik';
import News from './components/Main/News';

import './App.css'

interface ClubContact {
  club: string,
  mail: string,
  tel: string,
  altTel: string,
  address: string
}

const App = () => {

  const [menuExpandFinished, setMenuExpandFinished] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showNews, setShowNews] = useState<boolean>(false);
  // const [showMain, setShowMain] = useState(true);
  

  const contactInfo: ClubContact[] = [
    {club: "solna", mail: "info@forcafighting.com", tel: "073-736 08 44", altTel: "073-726 08 33", address: "Ankdammsgatan 23, Solna"},
    {club: "ursvik", mail: "info@forcafighting.com", tel: "073-736 08 44", altTel: "073-726 08 33", address: "Milstensvägen 52, Sundbyberg"},
  ]
  return (
    <>
      <Header menuExpand={menuExpandFinished} setMenuExpand={setMenuExpandFinished} showMenu={showMenu} setShowMenu={setShowMenu}/>
      <CSSTransition in={showMenu} timeout={400} classNames="menu-expand" unmountOnExit>
        <MobileMenu setShowMenu={setShowMenu}/>
      </CSSTransition>
      
      {showNews ? <News setShowNews={setShowNews}/> : null}
      <main className={showMenu ? "slide-out" : ""}>
        <Routes>
          <Route path="/" element={<Main contactInfo={contactInfo}/>}/>
          <Route path="/solna" element={<Solna contactInfo={contactInfo}/>}/>
          <Route path="/ursvik" element={<Ursvik contactInfo={contactInfo}/>}/>
        </Routes>
      </main>
       
    </>
  );
};

export default App;