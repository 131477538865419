interface Props {
    setShowNews: (boolean: boolean) => void,
}

const News = (props: Props) => {
    return (
        <section className='news-backdrop'>
            <article className="news">
                <button className='close-news' onClick={() => props.setShowNews(false)}>X</button>
                <h2>Glad sommar 2024!</h2>
                <h3>Under sommaren gäller följande:</h3>
                <div>
                    <p>Från och med <span>10/6</span> gäller sommarschema för Solna. Ursvik håller stängt under sommaren.</p>
                    <p>Se Solnas schema-sida för info om tider.</p>
                    <p>Solnas sommarschema gäller fram till 19/8. Ursvik öppnar igen 27/8.</p>
                    <p>Glad sommar!</p>
                    
                </div>
            </article>
        </section>
    );
};

export default News;