interface Props {
    club: string,
}

interface Props {
    club: string
}
const ClubStartpage = (props: Props) => {
    return (
        <section className={`startpage-wrapper ${props.club}`}>
            <h1 className="sr-only">FORCA FIGHTING {props.club}</h1> {/*This is for screen readers only */}
            <img src="./img/Forca-guld-transparent-small.png" alt="En man som tar stryptag på ett lejon bakifrån omgivna av en olivkrans. Under bilden står texten Forca Fighting." className="forca-logo club-logo"/>
            <h1 className="club-heading">{props.club}</h1>
        </section>
    );
};

export default ClubStartpage;