import Hamburger from './Hamburger';
import { useState, useEffect } from 'react';
import DesktopNav from './DesktopNav';

interface Props {
    menuExpand: boolean,
    setMenuExpand: (param: boolean) => void,
    showMenu: boolean,
    setShowMenu: (param: boolean) => void,
}
const Header = (props: Props) => {

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 700);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsDesktop(window.innerWidth > 700);
        })
    })

    return (
        <div className={`header`}>{/*Change className to react on whether the main content is visible or not */}
            {!isDesktop ? 
                <Hamburger menuExpand={props.menuExpand} setMenuExpand={props.setMenuExpand} showMenu={props.showMenu} setShowMenu={props.setShowMenu}/>
            :
                <DesktopNav/>}
        </div>
    );
};

export default Header;