import { Link } from 'react-router-dom';

interface Props {
    clubName: string,
    buttonClass: string,
    visibility: boolean,
    setVisibility: (param: boolean) => void,
}
const Button = (props: Props) => {
    const handleHover = () => {
        props.setVisibility(true);
    }
    const handleExit = () => {
        props.setVisibility(false)
    }

    return (
            <button className={`button-club ${props.buttonClass} ${props.visibility ? 'transparent' : ''}`} onMouseEnter={handleHover} onMouseLeave={handleExit}>
                <Link to={"/" + props.clubName} className={`club-link`} >
                    <p>{props.clubName}</p>
                </Link>
            </button>
    );
};

export default Button;
