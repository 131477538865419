import Day from './Day';
import FullWeek from './FullWeek';
import {useState, useEffect} from 'react';

interface Props {
    days: string[],
    activities: Activity[],
    ursvik: boolean,
}
interface Activity {
    day: string,
    time: string,
    type: string,
    room: string,
    grade: string,
    key: number
}

const ScheduleWrapper = (props: Props) => {
    
    let firstWeekday = props.days[0];
    
    const [currentDay, setCurrentDay] = useState(firstWeekday);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1000);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsDesktop(window.innerWidth > 1000);
        })
    })
    return (
        <article className="schedule-wrapper">
            <ul className="schedule-week">
                {props.days.map(day => {
                    return (
                        <li className={`schedule-day ${day === currentDay ? "selected-day" : ""}`} onClick={()=> setCurrentDay(day)} key={day}>{day}</li>
                    )
                })}
            </ul>
            {!isDesktop ? 
                <Day currentDay={currentDay} activities={props.activities} ursvik={props.ursvik}/> 
            :
                <FullWeek activities={props.activities} days={props.days} ursvik={props.ursvik}/>
            }
            <p>*Submission Wrestling</p>
            <p>**Mixed Martial Arts</p>
        </article>
    );
};

export default ScheduleWrapper;