interface IPrice {
    article: string,
    price: string,
}
interface Props {
    prices: IPrice[],
    ursvik: boolean,
}

const Prices = (props: Props) => {
    return (
        <section className="price-wrapper" id="prices">
            <h2>PRISER</h2>
            {/* {!props.ursvik ? 
            <>
                <div className="summer-prices">
                    <h3>Priser sommaren 2022</h3>
                    <p>Terminsavgift: 1200 SEK</p>
                </div>
                <h3>Ordinarie priser</h3>
            </>
            : null} */}
            
            <ul className="prices-ul">
                {props.prices.map(price => {
                    return (
                        <li key={price.article} className="price-listing">{price.article}: {price.price} SEK</li>
                    )
                })}
            </ul>
            <h3>Betalning sker genom Swish till {props.ursvik ? `123-566 15 25` : `123-669 37 66`}</h3>
        </section>
    );
};

export default Prices;