interface ClubContact {
    club: string,
    mail: string,
    tel: string,
    altTel: string,
    address: string
}
interface Props {
    contactInfo: ClubContact[],
    club: string
}

const Contact = (props: Props) => {
    return (
        <section className="contact-wrapper" id="contact">
            <h2>KONTAKT</h2>
            {props.contactInfo.map(contact => {
                return (contact.club === props.club ? 
                    <article className="contact-card" key={contact.club}>
                        {/* <h3>{contact.club}</h3> */}
                        <p>Epost: {contact.mail}</p>
                        <p>Telefon: {contact.tel} eller {contact.altTel}</p>
                        <p>Adress: {contact.address}</p>
                    </article>
                : props.club === "both" ? 
                    <article className="contact-card" key={contact.club}>
                        <h3>{contact.club}</h3>
                        <p>Epost: {contact.mail}</p>
                        <p>Telefon: {contact.tel} eller {contact.altTel}</p>
                        <p>Adress: {contact.address}</p>
                    </article>
                : null)
            })}
            
            {/* <article className="contact-card">
                <h3>URSVIK</h3>
                <p>Epost: info@forcafighting.com</p>
                <p>Telefon: 073-7360844 eller 073-7260833</p>
                <p>Adress: Milstensvägen 52, Sunbyberg.</p>
            </article> */}
        </section>
    );
};

export default Contact;