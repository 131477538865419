interface Props {
    activities: Activity[],
    days: string[],
    ursvik: boolean,
}
interface Activity {
    day: string,
    time: string,
    type: string,
    room: string,
    grade: string,
    key: number
}

const FullWeek = (props: Props) => {
    return (
        <article className='full-week'>
            {props.days.map(day => {
                return (
                    <ul className="activity-list" key={day}>
                        {props.activities.map(activity => {
                            return (activity.day === day ? 
                                <li className="activity-wrapper" key={activity.key}>
                                    <ul className="time-wrapper">
                                        <li><strong>{activity.time}</strong></li>
                                        {props.ursvik ? <li>{activity.room}</li> : null}
                                        <li>{activity.type}</li>
                                        <li>{activity.grade}</li>
                                    </ul>
                                </li>
                            : null)
                        })}
                    </ul>
                )
            })}
        </article>
    );
};

export default FullWeek;